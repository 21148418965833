.column {
  float: left;
  padding: 10px;
  height: 300px;
  width: 90%;
  height: 80%;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}
